<template style="margin:0px;padding:0px;">
    <v-container style="padding-top:0px!important;padding-left:20px;margin-left:0px;padding-right:20px!important;margin-right:0px!important;width:100%!important;min-width:100%;">
       
        <div>
            <div class="menuBotes">
                    <v-container class="">

                        <v-row>

                            <v-col cols="12" md="3">
                                <v-select class="select-input" color v-model="sistemaSelecionado" label="Sistemas" item-text="nome" 
                                    item-value="id" :items="listaDeSistemas"
                                    @change="buscarAtribuidasPendentes({idusuario:usuario.id})" outlined return-object hide-details dense>
                                </v-select>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-select class="select-input" color v-model="tipo_integracao" label="Tipo Integração" item-text="nome" 
                                    item-value="valor" :items="tiposintegracao" outlined return-object hide-details dense>
                                </v-select>
                                <!-- <v-select hide-details  :items="tiposintegracao" label="Tipo Integração" item-text="nome" item-value="value" v-model="tipo_integracao" return-object outlined>
                                </v-select> -->
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-text-field autofocus id="nome" type="text" label="Nome"  v-model="nomeCliente" outlined hide-details dense>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-text-field autofocus id="dti" type="date" label="Dt Inicial"  v-model="dtInicialUtilizacao" outlined hide-details dense>
                                </v-text-field>
                            </v-col>

                            <v-col cols="12" md="3">
                                <v-text-field autofocus id="dtf" type="date" label="Dt Final"  v-model="dtFinalUtilizacao" outlined hide-details dense>
                                </v-text-field>
                            </v-col>

                        </v-row>
                  
                        <v-row>

                            <v-col cols="12" md="3"></v-col>
                            <v-col cols="12" md="3"></v-col>
                            <v-col cols="12" md="3"></v-col>
                            <v-col cols="12" md="3">
                                <v-btn large elevation="" block color="info"    @click="buscarUtilizacao()" >{{txtConsultar}}</v-btn>
                            </v-col>

                        </v-row>

                    </v-container>
            
                                           
                                      
            </div>
               
        </div>
            
            
    

        <div>
          
              
             <v-container v-show="true" class="" style="padding-left:0px;margin-left:0px;padding-right:0px!important;margin-right:0px!important;width:100vw!important;min-width:100%;">
                <v-row>

                    <!-- GRAFICO DE BARRA DE QUANTIDADES -->
                    <v-col  >
                        <v-card class="" elevation="" max-width="" >
                          <v-card-text>
                            <div style="background-color:white;">
       

                                        <v-container class="" v-if="this.sistemaSelecionado.id == 1">
                                            
                                                <div style="display:flex; flex-direction: column;">
                                                    
                                                    
                                                    <div style="display:flex;margin-bottom:10px;">
                                                        <div style="display:flex;align-items:flex-end;width:50%;" >
                                                            <strong style="">Utilização Clientes</strong>
                                                        </div>
                    
                                                    </div>
                                                    
                                                            <v-data-table :items-per-page="15" :headers="headers" :items="listaDeUtilizacao " class="elevation-1" >
                                                                    <template v-slot:[`item.ultima_dia_ficha_finalizada`]="{ item }">
                                                                        <div>
                                                                            <div>{{item.ultima_dia_ficha_finalizada | formatarData}}</div>
                                                                        </div>
                                                                    </template>

                                                                    <template v-slot:[`item.percentual_economia_alcancado`]="{ item }">
                                                                        <div>
                                                                            <div>{{item.percentual_economia_alcancado | formatarMoeda}}</div>
                                                                        </div>
                                                                    </template>

                                                                    <template v-slot:[`item.economia_potencial_dinheiro`]="{ item }">
                                                                        <div>
                                                                            <div>R$ {{item.economia_potencial_dinheiro | formatarMoeda}}</div>
                                                                        </div>
                                                                    </template>

                                                                    <template v-slot:[`item.economia_realizada`]="{ item }">
                                                                        <div>
                                                                            <div>R$ {{item.economia_realizada | formatarMoeda}}</div>
                                                                        </div>
                                                                    </template>

                                                                    <template v-slot:[`item.nome_banco`]="{ item }">
                                                                        <v-btn icon @click="buscarRelatorioMensal(item)">
                                                                            <v-icon>mdi-trending-up</v-icon>
                                                                        </v-btn>
                                                                    </template>

                                                            </v-data-table>
                                                        </div>
                                                        <div>

                                                    

                                                    
                                                    
                                                            </div>
                                                            
                                        </v-container> 

                                        <v-container class="" v-if="this.sistemaSelecionado.id == 2">
                                         
                                             <div style="display:flex; flex-direction: column;">
                                                
                                                 
                                                 <div style="display:flex;margin-bottom:10px;">
                                                     <div style="display:flex;align-items:flex-end;width:50%;" >
                                                         <strong style="">Utilização Clientes Cardapio</strong>
                                                     </div>
                
                                                 </div>
                                                 
                                                        <v-data-table :items-per-page="15" :headers="headersCardapio" :items="listaDeUtilizacao " class="elevation-1" >
                                                                <!-- <template v-slot:[`item.ultima_dia_ficha_finalizada`]="{ item }">
                                                                    <div>
                                                                        <div>{{item.ultima_dia_ficha_finalizada | formatarData}}</div>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.percentual_economia_alcancado`]="{ item }">
                                                                    <div>
                                                                        <div>{{item.percentual_economia_alcancado | formatarMoeda}}</div>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.economia_potencial_dinheiro`]="{ item }">
                                                                    <div>
                                                                        <div>R$ {{item.economia_potencial_dinheiro | formatarMoeda}}</div>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:[`item.economia_realizada`]="{ item }">
                                                                    <div>
                                                                        <div>R$ {{item.economia_realizada | formatarMoeda}}</div>
                                                                    </div>
                                                                </template> -->

                                                        </v-data-table>
                                                    </div>
                                                    <div>

                                                

                                                   
                                                 
                                                        </div>
                                                        
                                        </v-container> 
                                      
                                        <v-container class="" v-if="this.sistemaSelecionado.id == 3">
                                         
                                         <div style="display:flex; flex-direction: column;">
                                            
                                             <div style="display:flex;margin-bottom:10px;">
                                                 <div style="display:flex;align-items:flex-end;width:50%;" >
                                                     <strong style="">Utilização Clientes FDGuest</strong>
                                                 </div>            
                                             </div>
                                            <v-data-table :items-per-page="15" :headers="headersFDGuest" :items="listaDeUtilizacao" class="elevation-1" >
                                            </v-data-table>
                                          </div>
                                                    
                                        </v-container> 
                                        
                                        <v-container class="" v-if="this.sistemaSelecionado.id == 34">
                                         
                                         <div style="display:flex; flex-direction: column;">
                                            
                                             <div style="display:flex;margin-bottom:10px;">
                                                 <div style="display:flex;align-items:flex-end;width:50%;" >
                                                     <strong style="">Utilização Clientes Checkout Express</strong>
                                                 </div>            
                                             </div>
                                            <v-data-table :items-per-page="15" :headers="headersCheckout" :items="listaDeUtilizacao" class="elevation-1" >
                                            </v-data-table>
                                          </div>
                                                    
                                        </v-container> 

                            </div>

                          </v-card-text>
                        
                        </v-card>
                    </v-col>
                    <!-- FIM DO GRAFICO DE BARRA DE QUANTIDADES -->

          
                </v-row>
              </v-container>
        </div>


    </v-container>

    
</template>

<script>

import ClienteServico from '@/services/cliente.service.js';
import SistemaServico from '@/services/sistema.service.js';
import dayjs from 'dayjs';


export default {
    name: 'UtilizacaoClientes',
  components: {
    
  },
  data(){

    return{
            // listaDeSistemas:[
            //     {id:1,nome:"Ficha Digital"},
            //     {id:2,nome:"Cardapio"},
            //     {id:3,nome:"FD Guest"},
            //     {id:4,nome:"E-Spot"},
            //     {id:30,nome:"Check-out Express"},
            //     {id:31,nome:"Check-in Pay"}
            // ],
            txtConsultar:'Consultar',
            listaDeSistemas: [],
            sistemaSelecionado:{id:1,nome:"Ficha Digital"},
            dtInicialUtilizacao:null,
            dtFinalUtilizacao:null,
            nomeCliente:null,
            tipo_integracao: {nome:'Todos',valor:0},
            tiposintegracao: [
                {nome:'Todos',valor:0},
                {nome:'Totvs Oracle',valor:1},
                {nome:'Totvs Sql Server',valor:2},
                {nome:'Hits',valor:3},
                {nome:'ERBON',valor:4},
                {nome:'ADMH',valor:5},
                {nome:'NEW HOTEL',valor:6},
            ],
            listaDeUtilizacao:[],
            headers: [
                { text: 'id', align: 'start', sortable: false, value: 'id', },
                { text: 'Nome', value: 'nome' },
                { text: 'Integração', value: 'nometipointegracao' },
                { text: 'Qtde Fichas', value: 'qtde_checkins_do_periodo' },
                { text: 'Qtde de Papel', value: 'economia_potencial_papel' },
                { text: 'Econo. Prev.', value: 'economia_potencial_dinheiro' },
                { text: 'Hóspedes Avulsos', value: 'qtdehospedesavulsos' },
                { text: 'Fichas Finalizadas', value: 'qtde_ficha_finalizada' },
                { text: '% Finalizadas', value: 'percentual_fichas_finalizadas' },
                { text: 'Econo. Real', value: 'economia_realizada' },       
                { text: '% Economia', value: 'percentual_economia_alcancado' },
                { text: 'Dt. Ult. Finalizada', value: 'ultima_dia_ficha_finalizada' },
                { text: 'Emails Sucesso', value: 'qtdeemailsucesso' },
                { text: 'Emails Erro', value: 'qtdeemailsemsucesso' },
                { text: 'Relatório Mensal', value: 'nome_banco' },
            ],
            headersCardapio: [
                { text: 'id',with:'5%', align: 'start', sortable: false, value: 'id', },
                { text: 'Nome', value: 'nome',width:'20%' },
                { text: 'Integração', value: 'tipointegracao',width:'10%' },
                { text: 'Comandas', value: 'qtde_comanda',width:'10%' },
                { text: 'Fechadas Quarto', value: 'qtde_comandas_fechadas_quarto',width:'10%' },
                { text: 'Fechadas Restaurante', value: 'qtde_comandas_fechadas_restaurante',width:'10%' },
                { text: 'Abertas', value: 'qtde_comandasabertas',width:'10%' },
                { text: 'Fechadas e Integradas', value: 'qtde_comandasfechadaseintegradas',width:'10%' },
                { text: 'Fechadas Ñ Integradas', value: 'qtde_comandasfechadasenaointegradas',width:'10%' },       
                { text: '% Fechadas', value: 'percentual_fechadas',width:'10%' },
                { text: '% Fechadas Ñ Integradas', value: 'percentual_comandasfechadasenaointegradas',width:'10%' }
            ],
            headersFDGuest: [
                { text: 'id', align: 'start', sortable: false, value: 'id', },
                { text: 'Nome', value: 'nome' },
                { text: 'Checkins', value: 'qtdecheckins'},
                { text: 'Chamados', value: 'qtdechamados'},
                { text: 'Pendentes', value: 'qtdependentes'},
                { text: 'Em Atendimento', value: 'qtdeematendimento'},
                { text: 'Atendidos', value: 'qtdeatendidos'},
                { text: '% Total', value: 'percatendidos'},
                { text: 'Não Atendidos', value: 'qtdenaoatendidos'},
                { text: 'Constestados', value: 'qtdeconstestados'},
                { text: 'Cancelados', value: 'qtdecancelados'},
                { text: 'Reviews', value: 'qtdereviews'},
            ],
            headersCheckout: [
                { text: 'id', align: 'start', sortable: false, value: 'id', },
                { text: 'Nome', value: 'nome' },
                { text: 'Checkouts', value: 'qtdecheckouts'},
                { text: 'Iniciados', value: 'qtdeiniciados'},
                { text: 'Não Concluído', value: 'qtdenaoconcluidos'},
                { text: 'Finalizados', value: 'qtdefinalizados'},
                { text: '% Finalizado', value: 'percfinalizados'},
                { text: 'Baixado', value: 'qtdebaixados'},
                { text: 'Estornado', value: 'qtdeestornados'},
            ]

    }
  },
  
  methods:{

    async buscarRelatorioMensal(item) {
      try {
        console.log(item)
        // const nome_dominio = item.nome_banco.substring(3).trim();
        // const url = `https://api${nome_dominio}.fd.economysoftware.com.br/dashboard/relatorio/mensal`;
        const url = `/implantacao/relatorioMensal`;
        //const resposta = await axios.get(url);
        console.error(`${url}`)
        
        const resposta = await ClienteServico.buscarRelatorioMensal({url:url+'?idcliente='+item.id+'&dtini='+this.dtInicialUtilizacao+'&dtfim='+this.dtFinalUtilizacao});
        const innerHTMLReport = resposta.data.innerHTMLReport;
        this.exibirConteudoNovaAba(innerHTMLReport);
      } catch (error) {
        // Trate o erro adequadamente
        console.error(error);
      }
    },

    exibirConteudoNovaAba(content) {
        console.error(`${JSON.stringify(content)}`)
        const newTab = window.open();
        newTab.document.write(content);
        newTab.document.close();
    },


    async buscarUtilizacao() {
        this.listaDeUtilizacao = []
        if(this.sistemaSelecionado.id == 1){
            this.txtConsultar = 'Aguarde...'
            const urlgerarRelatorioUtilizacaoFicha = `/implantacao/gerarRelatorioUtilizacaoFicha`;
            const gerarRelatorioUtilizacaoFicha = await ClienteServico.gerarRelatorioUtilizacaoFicha({url:urlgerarRelatorioUtilizacaoFicha,dtini:this.dtInicialUtilizacao,dtfim:this.dtFinalUtilizacao});
            console.log(gerarRelatorioUtilizacaoFicha)
        }
        const utilizacao = await ClienteServico.buscarUtilizacao({sistema:this.sistemaSelecionado.id,dtUtilizacaoInicial:this.dtInicialUtilizacao,dtUtilizacaoFinal:this.dtFinalUtilizacao,nomeCliente:this.nomeCliente,id_tipo_integracao:this.tipo_integracao.valor});
        this.listaDeUtilizacao = utilizacao.data;
        this.txtConsultar = 'Consultar'
        //console.log('utilizacao.data >>> ',JSON.stringify(utilizacao.data,null,2))
    },
    
    async buscarSistemas() {
        this.listaDeSistemas = []
        const sis = await SistemaServico.buscar({orderby: 'id'});
        this.listaDeSistemas = sis.data;
        //console.log('utilizacao.data >>> ',JSON.stringify(utilizacao.data,null,2))
    }
  },

    computed: {
        
    },
    
  async mounted(){
    // this.dtInicialUtilizacao = dayjs().subtract(30, 'day').format('YYYY-MM-DD')
    this.dtInicialUtilizacao = dayjs().format('YYYY-MM-DD')
    this.dtFinalUtilizacao = dayjs().format('YYYY-MM-DD')
    await this.buscarSistemas();
    /* await this.buscarUtilizacao(); */
    
     
  }
}
</script>

<style>


.v-data-table { 
  overflow-x: auto;
}

div::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */

}
.title{
font-weight:900;
justify-content: center;
align-items: center;
display: flex;
font-size: medium;
}
.select-input{
width: 100vh;

}
.div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.es-checkbox {
    padding: 0.2rem;
}

.imgPrioridade {
    width: 45px;
}

.imgBug {
    width: 30px;
}

.imagemFeature {
    filter: drop-shadow(0px 0px 2px #222);
    width: 30px;
}

.btnFecharDetalhePrioridade {
    background-color: #1377F2;
    width: 100px;
    color: white;
    border-radius: 8px;
    margin-top: 20px;
    margin: 10px;
}

.buttonExportarAtividade {
    background-color: #1377F2;
    width: 150px;
    color: white;
    border-radius: 8px;
    padding: 5px;
    margin: 5px;
}

.containerDetalhesPrioridades {
    text-align: center;
    padding: 20px;
    overflow-wrap: break-word;
}

.imgPrioridadeNull {
    width: 20px;
}

.containerBtnNovasPrioridades {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    margin: 10px;
    margin-bottom: 20px;
}

.v-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.menuBotes {
    width: 100%;
    padding: 20px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.336);
    border-radius: 5px;
    margin-top: 20px;
}

.iconeDeletarPrioridade {
    width: 20px;
}

.deletarPrioridadeBtn {
    background-color: #a80000;
    width: 100px;
    color: white;
    border-radius: 8px;
    margin-top: 40px;
    margin: 10px;
}

.cancelarDeletarPrioridade {
    background-color: #1377F2;
    width: 100px;
    color: white;
    border-radius: 8px;
    margin-top: 30px;
    margin: 10px;
}

.np-btn {
    background-color: #1377F2;
    width: 150px;
    height: 30px;
    color: white;
    border-radius: 8px;
    margin-top: 20px;
    margin-left: auto;
}

.containerAcoesProjetos {
    display: flex;
}

.buttonDeletePrioridade {
    margin-left: 15px;
}

.textFieldDateFiltroRelatorio {
width: 100%;
border: 1px solid rgb(196, 196, 196);
margin-right: 10px;
margin-left: 5px;
border-radius: 5px;
padding: 4px;
}
#labelDateRelatorioFiltro {
  margin-bottom: 10px;
}

.ContainerinputDataEstimativa {
  display: flex;
  margin-bottom: 20px;
  border-top: 1px solid rgb(218, 218, 218);
  padding: 10px;
  margin-bottom: 30px;
  margin-top: 10px;
  align-items: center;
}

.inputRadio {
  margin-left: 20px;
  margin-right: 5px;
}

.inputRadio1 {
  margin-right: 5px;
}

.containerTituloAtividadeExportar {
  border-bottom: 1px solid rgb(218, 218, 218);
  margin-bottom: 20px;
  text-align: center;
  padding: 15px;
}

.containerFiltrosAtividades {
  padding: 15px;
}
</style>